import { Scroll, Preload, ScrollControls, Text } from '@react-three/drei'
import { Html } from "@react-three/drei"
import { useThree } from '@react-three/fiber'
import { useNavigate } from 'react-router-dom'; 
import { useState } from 'react';

export default function Projects() {
    return (
        <div style={{ 
            position: 'absolute', 
            top: '10vh', 
            left: 0, 
            // zIndex: 1, // Ensure Projects has a lower z-index than the overlay
            pointerEvents: 'none', // So it doesn’t interfere with interactions
            textAlign: 'left', 
            display: 'flex', // Use flexbox for responsive layout
            flexDirection: 'column', // Stack items vertically
        }}>
            <a href="/" className="projects__link" style={{ fontSize: '10vw', display: 'block', marginBottom: '10vh', textAlign: 'left'}}>
                computer vision 
            </a>
            <a href="/photography" className="projects__link" style={{ fontSize: '10vw', display: 'block', marginBottom: '10vh', textAlign: 'left' }}>
                photography
            </a>
            <a href="/" className="projects__link" style={{ fontSize: '10vw', display: 'block', marginBottom: '10vh', textAlign: 'left' }}>
                sketches
            </a>
            <a href="/" className="projects__link" style={{ fontSize: '10vw', display: 'block', marginBottom: '10vh', textAlign: 'left' }}>
                screen print
            </a>
            <a href="/tmp" className="projects__link" style={{ fontSize: '10vw', display: 'block', marginBottom: '10vh', textAlign: 'left' }}>
                3d
            </a>
        </div>
    );
} 
