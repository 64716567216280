import { Scroll, Preload, ScrollControls } from '@react-three/drei'
import '../styles.css'
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import { MathJaxContext } from 'better-react-mathjax';
import { MathJaxProvider, MathJaxFormula } from 'mathjax3-react';

export default function BlogPost1() {
    return (    
    <div className="row"
        style={{ 
            position: 'absolute', 
            top: '10vh', 
            left: 0, 
            pointerEvents: 'none', // So it doesn’t interfere with interactions
            textAlign: 'left', 
            display: 'flex', // Use flexbox for responsive layout
            flexDirection: 'column', // Stack items vertically
            paddingBottom: '10vh'
            }}
    >
        <div className="card">
            <a style={{ fontSize: '40px', fontWeight: '600'}}>
                RL: FINITE MARKOV DECISION PROCESSES
            </a>
            <h5>A gentle introduction to Finite Markov Decision Processes - the fundamental reinforcement learning framework</h5>
            <div style={{ marginBottom: '20px' }}> {/* Adds 20px space below this block */}
                <Latex>
                    In a finite Markov decision process, or finite MDP, we have a learner/decision maker called the agent, which interacts 
                    with an environment at each of a sequence of discrete time steps $t=0,1,2,3,\dots$ . At each time step $t$, the agent 
                    observes the environment’s state {String.raw`$S_t\in\mathcal{S}$`} and on that basis, takes an action {String.raw`$A_t\in\mathcal{A}(s)$`}. 
                    One time step later, as a consequence of its action, the agent receives a numerical reward {String.raw`$R_{t+1}\in\mathcal{R} \subset \mathbb{R}$`} 
                    and finds itself in a new state {String.raw`$S_{t+1}$`} (This notation emphasizes that the next reward and next state are jointly determined). 
                    This results in the following sequence or trajectory:            
                </Latex>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                    <Latex>
                    {String.raw`$S_0 \rightarrow A_0 \rightarrow R_1,S_1 \rightarrow A_1 \rightarrow R_2,S_2 \rightarrow A_2 \rightarrow R_3,S_3\dots$`}
                </Latex>
            </div>
            <div style={{ marginBottom: '20px', marginTop: '20px'}}>
                <Latex>
                In a finite MDP, the sets of states, actions, and rewards ({String.raw`$\mathcal{S}$, $\mathcal{A}$, and $\mathcal{R}$`}) all have a finite
                number of elements. In this case, the random variables $R_t$ and $S_t$ have well defined
                discrete probability distributions dependent only on the preceding state and action. That
                is, for particular realizations of these random variables, {String.raw`$s’ \in \mathcal{S}$`} and {String.raw`$r’ \in \mathcal{R}$`}, there is a probability
                of those values occurring at time $t$, given particular values of the preceding state and
                action $s$ and $a$:
                </Latex>
            </div>
            {/* <div>
                <MathJaxProvider>
                    <MathJaxFormula 
                        formula="\begin{equation} p(s',r|s,a)\doteq \text{Pr}\{S_t = s', R_t=r|S_{t-1}=s, A_{t-1}=a\}\end{equation}" />
                </MathJaxProvider>
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                <Latex>
                    {String.raw`$
                        G_t \doteq R_{t+1} + \gamma R_{t+2} + \gamma^2 R_{t+3} + \gamma^3 R_{t+4} +\dots 
                        \\  \;\;\;\;\; = R_{t+1} + \gamma (R_{t+2} + \gamma R_{t+3} + \gamma^2 R_{t+4} + \dots ) 
                        \\ \;\;\;\;\; = R_{t+1} + \gamma G_{t+1}
                    $`}
                </Latex>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                <Latex>
                    {String.raw`$
                        v_*(s)= \max_{a\in\mathcal{A}(s)} q_{\pi*}(s,a)
                        \\  \;\;\;\;\;\;\;\:\:=\max_a \mathbb{E}_{\pi *}[G_t|S_t=s, A_t=a]
                        \\  \;\;\;\;\;\;\;\:\:=\max_a\mathbb{E}_{\pi *}[R_{t+1}+\gamma G_{t+1}|S_t=s, A_t=a]
                        \\  \;\;\;\;\;\;\;\:\:=\max_a\mathbb{E}_{}[R_{t+1}+\gamma v_*(S_{t+1})|S_t=s, A_t=a]
                        \\  \;\;\;\;\;\;\;\:\:=\max_a \sum_{s',r} p(s',r|s,a)[r+\gamma v_* (s')]
                    $`}
                </Latex>
            </div>
        </div>
    </div>
    );
}
