import React, { useState } from 'react'

export default function Overlay() {
    const [isOpen, setIsOpen] = useState(false);  

    const handleHamburgerClick = () => {
        setIsOpen(!isOpen);
    }; 
      
    const handleLinkClick = () => {
        setIsOpen(false); // Close the navigation when a link is clicked
    };
  return (
    <>
    <header className="header">
        <div className='header__content' >
            <a href="/" className="logo"> aarist </a>
            <nav className={`nav ${isOpen ? 'nav--open' : ''}`}>               
                <ul className="nav__list">
                    <li>
                        <a href="/projects" className="nav__link" onClick={handleLinkClick}> projects </a>
                    </li>
                    <li>
                        <a href="/blog" className="nav__link" onClick={handleLinkClick}> blog </a>
                    </li>
                    <li>
                        <a href="/about" className="nav__link" onClick={handleLinkClick}> about </a>
                    </li>
                </ul>
            </nav>
            <div className="hamburger" onClick={handleHamburgerClick}>
                <div className={`bar ${isOpen ? 'bar--rotate1' : ''}`}></div>
                <div className={`bar ${isOpen ? 'bar--hidden' : ''}`}></div>
                <div className={`bar ${isOpen ? 'bar--rotate2' : ''}`}></div>
            </div>
        </div>
    </header>
    <footer className="footer">
      <a style={{ position: 'fixed', bottom: 30, left: 90, fontSize: '13px' }}>
        ---
        <br />
        ---
      </a>
      <div style={{ position: 'fixed', bottom: 30, right: 40, fontSize: '13px' }}>  {new Date().getFullYear()} </div>
      <img src="/600px-ArmenianEternity.svg.png" style={{ position: 'fixed', bottom: 30, left: 40, width: 30 }} />
    </footer>
    </>
  )
}
