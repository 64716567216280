import { Scroll, Preload, ScrollControls } from '@react-three/drei'
import '../styles.css'

export default function Blog() {
    return (            
    <div className="row"
    style={{ 
        position: 'absolute', 
        top: '10vh', 
        left: 0, 
        pointerEvents: 'none', // So it doesn’t interfere with interactions
        textAlign: 'left', 
        display: 'flex', // Use flexbox for responsive layout
        flexDirection: 'column', // Stack items vertically
        }}
    >
        <div className="card">
            <a href="/blog/rl_finite_mdp" className="anchor"  style={{ fontSize: '40px', fontWeight: '600'}}>
                RL: FINITE MARKOV DECISION PROCESSES
            </a>
            <h5>A gentle introduction to Finite Markov Decision Processes - the fundamental reinforcement learning framework</h5>
            {/* <div className="fakeimg" >Image</div> */}
        </div>
        <div className="card">
            <h2>TITLE HEADING</h2>
            <h5>Title description</h5>
            {/* <div className="fakeimg" >Image</div> */}
            <p>Some text..</p>
        </div>
        <div className="card">
            <h2>TITLE HEADING</h2>
            <h5>Title description</h5>
            {/* <div className="fakeimg" >Image</div> */}
            <p>Some text..</p>
        </div>
        <div className="card">
            <h2>TITLE HEADING</h2>
            <h5>Title description</h5>
            {/* <div className="fakeimg" >Image</div> */}
            <p>Some text..</p>
        </div>
        <div className="card" style={{ marginBottom: '10vh'}}>
            <h2>TITLE HEADING</h2>
            <h5>Title description</h5>
            {/* <div className="fakeimg" >Image</div> */}
            <p>Some text..</p>
        </div>
    </div>
    );
}
