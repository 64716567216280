import './styles.css'
import * as THREE from 'three'
import React, { useRef, useState } from 'react'
import { Canvas, createPortal, useFrame, useThree } from '@react-three/fiber'
import { useFBO, useGLTF, useScroll, Text, Image, Scroll, Preload, ScrollControls, MeshTransmissionMaterial } from '@react-three/drei'
import { easing } from 'maath'
import { createRoot } from 'react-dom/client'
import { OrbitControls } from '@react-three/drei'
import Home from './components/Home';
import Blog from './components/Blog';
import About from './components/About';
import Projects from './components/Projects';
import Box from './components/Box';
import Overlay from './components/Overlay';
import Photography from './components/Photography'

//Routing and browser history
import { createBrowserHistory } from "history";
import { Router, Link, Route, Routes } from "react-router-dom";
import BlogPost1 from './components/BlogPost1'
import { MathJaxContext } from 'better-react-mathjax'

const history = createBrowserHistory({ window });

export function BrowserRouter({ children, history }) {
  let [state, dispatch] = React.useReducer((_, action) => action, {
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(dispatch), [history]);

  return (
    <Router
      children={children}
      action={state.action}
      location={state.location}
      navigator={history}
    />
  );
}

function Scene() {
    return (
        <>
        <Canvas camera={{ position: [0, 0, 20], fov: 15 }}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/tmp" element={<Box position={[-1.2, 0, 0]}/>} />
                <Route path="/photography" element={<Photography />} />
                {/* <Route path="/projects" element={<Projects />} /> */}
            </Routes>
        </Canvas>
        <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/blog/rl_finite_mdp" element={<BlogPost1 />} />
        </Routes>
        </>
    )
}

export default function App() {
  return (
    <BrowserRouter history={history}>
      <Scene />
    </BrowserRouter>
  )
}

createRoot(document.getElementById('root')).render(
    <>
        <App />
        <Overlay />
    </>
)
