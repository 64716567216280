
import { useScroll, Text, Image, Scroll, Preload, ScrollControls } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useRef } from 'react'

function Images() {
    const group = useRef()
    const data = useScroll()
    const aspectRatioBackground = 2000 / 7000; 
    const scaleFactor = 20
    const { width, height } = useThree((state) => state.viewport)
    useFrame(() => {
        group.current.children[0].material.zoom = 1 + data.range(0, 1 / 3) / 9
        // group.current.children[0].material.zoom = 1 + data.range(0, 1 / 3) / 3
        // group.current.children[1].material.zoom = 1 + data.range(0, 1 / 3) / 3
        // group.current.children[2].material.zoom = 1 + data.range(1.15 / 3, 1 / 3) / 2
        // group.current.children[3].material.zoom = 1 + data.range(1.15 / 3, 1 / 3) / 2
        // group.current.children[4].material.zoom = 1 + data.range(1.15 / 3, 1 / 3) / 2
        // group.current.children[5].material.grayscale = 1 - data.range(1.6 / 3, 1 / 3)
        // group.current.children[6].material.zoom = 1 + (1 - data.range(2 / 3, 1 / 3)) / 3
    })
    return (
        <group ref={group}>
             {/* <Image position={[-2, -1, 0]} scale={[1, 5, 0]} url="/background.jpg" /> */}
             <Image position={[-3, -7, 0]} scale={[aspectRatioBackground * scaleFactor, scaleFactor, 0]} url="/background.jpg" />

            {/* <Image position={[-2, 0, 0]} scale={[4, height, 1]} url="/1.jpg" />
            <Image position={[2, 0, 3]} scale={3} url="/img6.jpg" />
            <Image position={[-2.05, -height, 6]} scale={[1, 3, 1]} url="/trip2.jpg" />
            <Image position={[-0.6, -height, 9]} scale={[1, 2, 1]} url="/img8.jpg" />
            <Image position={[0.75, -height, 10.5]} scale={1.5} url="/trip4.jpg" />
            <Image position={[0, -height * 1.5, 7.5]} scale={[1.5, 3, 1]} url="/img3.jpg" />
            <Image position={[0, -height * 2 - height / 4, 0]} scale={[width, height / 1.1, 1]} url="/img7.jpg" /> */}
        </group>
    )
}
  
function Typography() {
    const state = useThree()
    const { width, height } = state.viewport.getCurrentViewport(state.camera, [0, 0, 12])
    const fontSize = width / 10;
    const shared = { font: '/Inter-Regular.woff', color: 'black', fontSize }
    return (
        <>
            <Text children="hello" anchorX="right" position={[width / 2.5, -height / 10, 12]} {...shared} />
            <Text children="բարև" position={[width / 5, -height / 1, 12]} {...shared} />
            <Text  children={"things\nstuff"} anchorX="right" position={[width / 2.5, -height * 2, 12]} {...shared} />
            <Text  children={"and\nmore"} anchorX="right" position={[width / 2.5, -height * 5, 12]} {...shared} />

            {/* <Text children="computer vision" anchorX="left" position={[-width / 2.5, -height / 10, 12]} {...shared} />
            <Text children="be" anchorX="right" position={[width / 2.5, -height * 2, 12]} {...shared} />
            <Text children="home" position={[0, -height * 4.624, 12]} {...shared} /> */}
        </>
    )
}

export default function Home() {
    return (
        <ScrollControls damping={0.2} pages={3} distance={0.5}>
            <Scroll>
            <Typography />
            <Images />
            </Scroll>
            <Scroll html>
            {/* <div style={{ transform: 'translate3d(65vw, 192vh, 0)' }}>
                PMNDRS Pendant lamp
                <br />
                bronze, 38 cm
                <br />
                CHF 59.95
                <br />
            </div> */}
            </Scroll>
            <Preload />
        </ScrollControls>
    );
}